import { Injectable } from '@angular/core';

import {
  SearchReindexStatus,
  SearchReindexStatusEnum
} from '@sk-models';

@Injectable()
export class SearchReindexService {
  computeJobProperties(job: SearchReindexStatus): SearchReindexStatus {
    if (!job) {
      return null;
    }

    switch (+job.status) {
    case SearchReindexStatusEnum.InProgress: {
      job.statusIcon = 'favorite';
      job.statusIconTitle = 'Running';
      job.statusIconClass = 'success';
      break;
    }
    case SearchReindexStatusEnum.Error: {
      job.statusIcon = 'close';
      job.statusIconTitle = 'Failed';
      job.statusIconClass = 'error';
      break;
    }
    case SearchReindexStatusEnum.Success: {
      job.statusIcon = 'done';
      job.statusIconTitle = 'Succeeded';
      job.statusIconClass = 'success';
      break;
    }
    case SearchReindexStatusEnum.Stalled: {
      job.statusIcon = 'favorite';
      job.statusIconTitle = 'Stalled';
      job.statusIconClass = 'warning';
      break;
    }
    }

    // Exchange has different resource types, but each one has a unique subscription
    // This is unlike groups where a single subscription ID can have multiple resource types
    if(job.subscriptionType.toLocaleLowerCase() != 'exchange') {
      job.mappedResourceType = job.subscriptionType;
    }



    return job;
  }
}
