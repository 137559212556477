import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BackupSubscription, MachineState } from '@sk-models';
import { ApiService } from '../../api-service/api.service';

@Injectable()
export class SubscriptionService {
  private subscriptionSubject = new BehaviorSubject<BackupSubscription>(null);
  public subscription$ = this.subscriptionSubject.asObservable();

  private get subscription(): BackupSubscription {
    return this.subscriptionSubject.value;
  }

  constructor(
    private apiService: ApiService
  ) { }

  getSubscription(orderId: string, id: string, resourceType: string = null): void {
    if (this.subscription && this.subscription.id === id && this.subscription.resourceKey.resourceType === resourceType) {
      this.setSubscription(this.subscription);
    } else {
      let odataQuery = `$top=1&$filter=BackupServiceId eq ${orderId} and Id eq ${id}`
      if (resourceType) {
        odataQuery += ` and ResourceKey/ResourceType eq '${resourceType}'`;
      }
      this.apiService
        .findSubscriptions(odataQuery)
        .subscribe(x => {
          if (x.value?.length > 0) {
            this.setSubscription(x.value[0]);
          } else {
            this.setSubscription(null);
          }
        });
    }
  }

  setSubscription(sub: BackupSubscription): void {
    this.subscriptionSubject.next(sub);
  }

  loadRestoreMachineState(): void {
    this.apiService
      .fetchRestoreMachineState(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe(x => {
        if (x){
          this.subscription.restoreState = x;
        } else {
          this.subscription.restoreState = new MachineState();
        }
      });
  }

  loadSnapshotMachineState(): void {
    this.apiService
      .fetchSnapshotMachineState(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe(x => {
        if (x){
          this.subscription.snapshotState = x;
        } else {
          this.subscription.snapshotState = new MachineState();
        }
      });
  }

  loadPurgeMachineState(): void {
    this.apiService
      .fetchPurgeMachineState(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe(x => {
        if (x){
          this.subscription.purgeState = x;
        } else {
          this.subscription.purgeState = new MachineState();
        }
      });
  }

  loadMaintenanceMachineState(): void {
    this.apiService
      .fetchMaintenanceMachineState(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe(x => {
        if (x){
          this.subscription.maintenanceState = x;
        } else {
          this.subscription.maintenanceState = new MachineState();
        }
      });
  }

  loadInitialSnapshotDate(): void {
    this.apiService
      .fetchInitialSnapshotDate(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe(x => {
        this.subscription.initialSnapshotCompletedOn = x;
      });
  }

  loadSnapshotQueueName(): void {
    this.apiService
      .fetchSnapshotQueueName(this.subscription.backupServiceId, this.subscription.resourceKey)
      .subscribe(x => {
        this.subscription.snapshotQueue = x.queueName;
        this.subscription.snapshotFrequency = x.frequency;
      })
  }
}
