<sk-reindex-history
	[orderId]="orderId"
	[filter]="filterData"
	[jobs]="jobs"
  [isRefreshing]="isRefreshing"
	(refreshData)="onRefreshData()">
		<sk-reindex-history-filter
			[jobStatuses]="jobStatuses"
      [resourceTypes]="resourceTypes"
      (filterChanged)="onFilterChanged($event)">
		</sk-reindex-history-filter	>
</sk-reindex-history>
