import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  LicensingItem,
  LicensingItemChild,
  LicensingItemType,
} from '@sk-models';

@Component({
  selector: 'sk-licensing-drawer-details',
  templateUrl: './licensing-drawer-details.component.html',
  styleUrls: ['./licensing-drawer-details.component.scss']
})
export class LicensingDrawerDetailsComponent implements OnInit {

  @Output() navigateToItem = new EventEmitter<LicensingItemChild>();

  @Input() item: LicensingItem;
  @Input() itemType: LicensingItemType;

  displayedColumns = [ "name" ];

  licensesClickedOpen: boolean;
  skusClickedOpen: boolean;
  usersClickedOpen: boolean;
  openLists: LicensingItemType[] = [];

  ngOnInit(): void {
    switch(this.item.childItems[0].type) {
    case LicensingItemType.License:
      this.licensesClickedOpen = true;
      this.skusClickedOpen = false;
      this.usersClickedOpen = false;
      break;
    case LicensingItemType.SKU:
      this.skusClickedOpen = true;
      this.usersClickedOpen = false;
      this.licensesClickedOpen = false;
      break;
    case LicensingItemType.User:
      this.usersClickedOpen = true;
      this.skusClickedOpen = false;
      this.licensesClickedOpen = false;
      break;
    }
    this.createOpenLists();
  }

  clickedType(type: LicensingItemType): void {
    switch(type) {
    case LicensingItemType.License:
      this.licensesClickedOpen = !this.licensesClickedOpen;
      break;
    case LicensingItemType.SKU:
      this.skusClickedOpen = !this.skusClickedOpen;
      break;
    case LicensingItemType.User:
      this.usersClickedOpen = !this.usersClickedOpen;
      break;
    }
    this.createOpenLists();
  }

  createOpenLists(): void {
    this.openLists = [];
    if (this.licensesClickedOpen) {
      this.openLists.push(LicensingItemType.License)
    }
    if (this.skusClickedOpen) {
      this.openLists.push(LicensingItemType.SKU);
    }
    if (this.usersClickedOpen) {
      this.openLists.push(LicensingItemType.User);
    }
  }

  onNavigateToItem(grandChild: { name: string, id: string, idName: string }, type: LicensingItemType): void {
    const grandChildWithType: LicensingItemChild = { type: type, details: [ grandChild ] };
    this.navigateToItem.emit(grandChildWithType);
  }
}
