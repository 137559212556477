import {
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElasticSearchRequest } from 'app/models/elastic-search-request';
import { Moment } from 'moment';
import {
  Observable
} from 'rxjs';
import {
  map
} from 'rxjs/operators';

import * as models from '@sk-models';
import { BackupEntity, BulkReindexOrderRequest, BulkReindexOrderResponse, OrderResourceCount } from '@sk-models';

import { environment } from '../../environments/environment';
// don't use @sk-services import because it wil cause a circular dependancy issue
import { SkyKickApiService } from '@sk-services';
import { SnaphostFrequencyInfo } from "../snapshot-frequencies/models/snaphost-frequency-info";

@Injectable()
export class ApiService {

  authToken: string;

  constructor(
    private skApiService: SkyKickApiService) { }

  public getPartners(searchTerm: string, reload = false): Observable<models.Partner[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/partners/search?searchTerm=${searchTerm}`,
      null,
      null,
      reload
    );
  }

  public getPartner(partnerId: string, reload = false): Observable<models.PartnerDetails> {
    return this.makeRequest('GET',
      `${environment.api_url()}/partners/${partnerId}`,
      null,
      null,
      reload
    );
  }

  fetchGroupMembership(): Observable<string> {
    return this.makeRequest('GET',
      `${environment.api_url()}/developer/membership`,
      null,
      null,
      false);
  }

  fetchOrder(orderId: string, reload = false): Observable<models.BackupOrder> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}`,
      null,
      null,
      reload);
  }

  fetchOrderName(orderId: string): Observable<string> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/name`,
      null,
      'text',
      false);
  }

  getRestoredItems(restoreId: string, resourceKey: models.ResourceKey): Observable<models.RestoredItem[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/restores/${restoreId}/items?` +
      `subscriptionId=${resourceKey.subscriptionId}&resourceType=${resourceKey.resourceType}`,
      null,
      null,
      false);
  }

  exportRestore(restoreId: string, resourceKey: models.ResourceKey): Observable<HttpResponse<Blob>> {
    return this.makeRequest('GET',
      `${environment.api_url()}/restores/${restoreId}/export?` +
      `subscriptionId=${resourceKey.subscriptionId}&resourceType=${resourceKey.resourceType}`,
      null,
      'blob',
      false,
      '300000');
  }

  fetchPartnerName(partnerId: string): Observable<string> {
    return this.makeRequest('GET',
      `${environment.api_url()}/partners/${partnerId}/name`,
      null,
      'text',
      false);
  }

  fetchContactName(contactId: string, odataQuery: string): Observable<models.Contact> {
    return this.makeRequest('GET',
      `${environment.odata_url()}/contactsv2(${contactId})?${odataQuery}`,
      null,
      null,
      false);
  }

  fetchJobByDate(jobId: string, startDate: string, reload = false): Observable<models.BackupJob>{
    return this.makeRequest('GET',
      `${environment.api_url()}/jobs/${jobId}/old?startDate=${startDate}`,
      null,
      null,
      reload);
  }

  fetchCrmOriginalOrder(migrationOrderId: string): Observable<models.MigrationOrder>{
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${migrationOrderId}/crmOrder`,
      null,
      null,
      true);
  }

  fetchSubscriptionJob(jobId: string, extended = false, reload = false): Observable<models.BackupJob> {
    return this.makeRequest('GET',
      `${environment.api_url()}/jobs/${jobId}?extended=${extended}`,
      null,
      null,
      reload);
  }

  fetchOrderJob(jobId: string, orderId: string, reload = false): Observable<models.BackupJob> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/jobs/${jobId}`,
      null,
      null,
      reload);
  }

  queryGraphCustom(orderId: string, uri: string, betaEndpoint: boolean, reload = true): Observable<models.GraphQueryResponse> {
    return this.makeRequest('POST',
      `${environment.api_url()}/graph/${orderId}/query`,
      {
        uri: uri,
        method: 'GET',
        betaEndpoint: betaEndpoint
      },
      null,
      reload
    );
  }

  queryGraphPredefined(orderId: string, predefinedQueryName: string, reload = true): Observable<models.GraphQueryResponse> {
    return this.makeRequest('GET',
      `${environment.api_url()}/graph/${orderId}/query/${predefinedQueryName}`,
      null,
      null,
      reload
    );
  }

  getGraphToken(orderId: string, reload = true): Observable<string> {
    return this.makeRequest('GET',
      `${environment.api_url()}/graph/${orderId}/token`,
      null,
      'text',
      reload
    );
  }

  getRestoreStatsForJob(jobId: string, reload = true): Observable<models.SubscriptionJobMetric[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/jobs/${jobId}/restore-stats`,
      null,
      null,
      reload);
  }

  getRestoreStatsSummaryForJob(jobId: string, reload = false): Observable<models.RestoreStatsSummary> {
    return this.makeRequest('GET',
      `${environment.api_url()}/jobs/${jobId}/restore-stats-summary`,
      null,
      null,
      reload);
  }

  getSnapshotStatsForJob(jobId: string, reload = true): Observable<models.SubscriptionJobMetric[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/jobs/${jobId}/snapshot-stats`,
      null,
      null,
      reload);
  }

  getGraphEndpointOptions(reload = false): Observable<string[][]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/graph/graph-options`,
      null,
      null,
      reload);
  }

  findOrderPromo(orderId: string, reload = false): Observable<models.Promo> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/promo`,
      null,
      null,
      reload);
  }

  completeOrder(orderId: string): Observable<models.BackupOrder> {
    return this.makeRequest('PUT',
      `${environment.api_url()}/orders/${orderId}/complete`,
      null,
      null,
      true);
  }

  purgeSubscription(orderId: string, resourceKey: models.ResourceKey, reload = false): Observable<string> {
    return this.makeRequest('DELETE',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/purge`,
      null,
      'text',
      reload,
      null,
      false);
  }

  requeueRestoreJob(jobId: string, requestBody: models.RestoreRequeueRequest, reload = true)
    : Observable<models.QueueJobResult> {
    const body = {
      backupServiceId: requestBody.backupServiceId,
      jobSerialization: requestBody.jobSerialization,
      resourceKey: requestBody.resourceKey,
      queueNewJob: requestBody.queueNewJob
    };
    return this.makeRequest('POST',
      `${environment.api_url()}/jobs/${jobId}/restore-requeue`,
      body,
      null,
      reload);
  }

  findBillingRecords(orderId: string,
    reload = false): Observable<models.BackupOrderBillingRecord[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/billing-records-ytd`,
      null,
      null,
      reload);
  }

  findBackupOrderJobs(orderId: string, days: number, reload: boolean): Observable<models.BackupJob[]> {

    let url = `${environment.api_url()}/orders/${orderId}/jobs`;
    if (days != null) {
      url += `?days=${days}`;
    }

    return this.makeRequest('GET',
      url,
      null,
      null,
      reload);
  }

  findBackupOrderSearchIndexStatuses(orderId: string, reload: boolean): Observable<models.SearchReindexStatus[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/reindexes`,
      null,
      null,
      reload);
  }

  findBackupOrderSubscriptionSearchIndexStatuses(orderId: string, subscriptionId: string, resourceType: string, reload: boolean):
    Observable<models.SearchReindexStatus[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/reindexes`,
      null,
      null,
      reload);
  }

  findBackupOrderSubscriptionSearchItemDetails(orderId: string, subscriptionId: string, resourceType: string, reload: boolean):
  Observable<models.SearchReindexStatus[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/reindexes`,
      null,
      null,
      reload);
  }

  findHumanAudits(domainEntityId: string, filters: models.AuditFilter, reload: boolean): Observable<models.AuditPage> {
    const body = {
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
      sortByColumn: filters.sortByColumn,
      sortByAscending: filters.sortByAscending
    };

    return this.makeRequest('POST',
      `${environment.api_url()}/audit/${domainEntityId}/human-audits`,
      body,
      null,
      reload);
  }

  findBackupServices(searchTerm: string, reload = false): Observable<models.BackupOrder[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/search?searchTerm=${searchTerm}`,
      null,
      null,
      reload
    );
  }


  findBackupServicesByVanityDomain(searchTerm: string, reload = false): Observable<models.BackupOrder[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/search-by-vanity-domain?searchTerm=${searchTerm}`,
      null,
      null,
      reload
    );
  }

  findMarketplaceOrders(searchTerm: string, reload = false): Observable<models.BackupOrder[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/search-by-marketplace-order?searchTerm=${searchTerm}`,
      null,
      null,
      reload
    );
  }

  findSubscriptionJobResults(jobId: string, reload = false): Observable<models.BackupJobStatisticsReport> {
    return this.makeRequest('GET',
      `${environment.api_url()}/jobs/${jobId}/results`,
      null,
      null,
      reload);
  }

  findSkippedRestoreItemResults(jobId: string,
    resourceKey: models.ResourceKey,
    sourceResourceKey: models.ResourceKey,
    reload = false): Observable<models.SkippedRestoreItem[]> {
    const body = {
      resourceKey: resourceKey,
      redirectRestoreSourceResourceKey: sourceResourceKey
    };
    return this.makeRequest('POST',
      `${environment.api_url()}/jobs/${jobId}/restore-skip-report`,
      body,
      null,
      reload);
  }

  exportSkippedRestoreItems(jobId: string,
    resourceKey: models.ResourceKey,
    sourceResourceKey: models.ResourceKey,): Observable<HttpResponse<Blob>> {
    const body = {
      resourceKey: resourceKey,
      redirectRestoreSourceResourceKey: sourceResourceKey
    };
    return this.makeRequest('POST',
      `${environment.api_url()}/jobs/${jobId}/restore-skip-report-export`,
      body,
      'blob',
      false,
      '300000');
  }


  findSkippedRestoreItemAggregate(reload = false): Observable<models.SkippedRestoreItem[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/restores/aggregate-skip-report`,
      null,
      null,
      reload);
  }

  queueOrderJob(orderId: string, jobType: string): Observable<models.QueueJobResult> {
    const body = {
      orderId: orderId,
      jobType: jobType
    };

    return this.makeRequest('POST',
      `${environment.api_url()}/jobs/enqueue-order-job`,
      body,
      null,
      true);  // Never use the cache for queueing jobs
  }

  queueSubscriptionJob(resourceKey: models.ResourceKey, jobType: string): Observable<models.QueueJobResult> {
    const body = {
      resourceKey: resourceKey,
      jobType: jobType
    };

    return this.makeRequest('POST',
      `${environment.api_url()}/jobs/enqueue-subscription-job`,
      body,
      null,
      true);  // Never use the cache for queueing jobs
  }

  setJobFinish(jobId: string, jobType: string, orderId: string, resourceKey: models.ResourceKey, reload = false):
    Observable<models.GenericResponse> {
    const body = {
      orderId: orderId,
      jobType: jobType,
      resourceKey: resourceKey
    };

    return this.makeRequest('POST',
      `${environment.api_url()}/jobs/${jobId}/force-finish`,
      body,
      null,
      reload);
  }

  findTenantUsers(orderId: string, reload = false): Observable<models.TenantUser[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/tenant-users`,
      null,
      null,
      reload);
  }

  getAccessDetails(orderId: string, accessType: string, reload = false, handleErrorExplicitly = false):
    Observable<models.BackupAccessDetails> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/access/${accessType}`,
      null,
      null,
      reload,
      null,
      true,
      handleErrorExplicitly);
  }

  listSnapshotRates(orderId: string, from: Moment, to: Moment, reload = false): Observable<models.SnapshotFrequency[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/snapshot-frequencies/${from.format('YYYY-MM-DD')}/${to.format('YYYY-MM-DD')}`,
      null,
      null,
      reload);
  }

  findSubscriptions(odataQuery: string, reload = false): Observable<models.ODataResponse<models.BackupSubscription>> {
    return this.makeRequest('GET',
      `${environment.odata_url()}/subscriptionsv2?${odataQuery}`,
      null,
      null,
      reload);
  }

  getSubscriptionsBySubscriptionId(subscriptionId: string): Observable<models.BackupSubscription[]> {
    const odataQuery = `$filter=Id eq ${subscriptionId}`
    return this.findSubscriptions(odataQuery)
      .pipe(map(x => x.value));
  }

  fetchRestoreMachineState(orderId: string, resourceKey: models.ResourceKey, reload = false)
    : Observable<models.MachineState> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/restore-state`,
      null,
      null,
      reload);
  }

  fetchSnapshotMachineState(orderId: string, resourceKey: models.ResourceKey, reload = false)
    : Observable<models.MachineState> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/snapshot-state`,
      null,
      null,
      reload);
  }

  fetchPurgeMachineState(orderId: string, resourceKey: models.ResourceKey, reload = false)
    : Observable<models.MachineState> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/purge-state`,
      null,
      null,
      reload);
  }

  fetchMaintenanceMachineState(orderId: string, resourceKey: models.ResourceKey, reload = false)
    : Observable<models.MachineState> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/maintenance-state`,
      null,
      null,
      reload);
  }

  fetchInitialSnapshotDate(orderId: string, resourceKey: models.ResourceKey, reload = false): Observable<Moment> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/init-snap`,
      null,
      null,
      reload);
  }

  fetchSubscriptionBackupSize(orderId: string, resourceKey: models.ResourceKey, reload = false): Observable<number> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/backup-size`,
      null,
      null,
      reload);
  }

  fetchSubscriptionLicenseDetails(orderId: string, o365Id: string, reload = false): Observable<models.LicenseDetails[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/licensing/${orderId}/license-details?o365Id=${o365Id}`,
      null,
      null,
      reload);
  }

  fetchSubscriptionAssignedLicenseDetails(orderId: string, reload = false): Observable<models.LicensingResponse> {
    return this.makeRequest('GET',
      `${environment.api_url()}/licensing/${orderId}`,
      null,
      null,
      reload);
  }

  findChildrenOfSubscriptionEntity(orderId: string, subscriptionId: string, resourceType: string,
    skyKickId: string = null, parentSkyKickId: string = null): Observable<models.TreeEntity[]> {
    let url = `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/structure`;
    if (skyKickId != null && parentSkyKickId != null) {
      url = `${url}?skyKickId=${skyKickId}&parentSkyKickId=${parentSkyKickId}`;
    }
    return this.makeRequest('POST',
      url,
      null,
      null,
      true,
      '180000'); /* A folder with 50+k items takes about 2 min to load. Let's assume 3 min is enough for large folders.*/
  }

  getStructureTreeToSubscriptionEntityWithChildren(orderId: string, subscriptionId: string, resourceType: string,
    skyKickId: string, parentSkyKickId: string): Observable<models.TreeEntity> {
    let url = `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/structure-walk-up`;
    url = `${url}?skyKickId=${skyKickId}&parentSkyKickId=${parentSkyKickId}`;
    return this.makeRequest('POST',
      url,
      null,
      null,
      true,
      '180000'); /* A folder with 50+k items takes about 2 min to load. Let's assume 3 min is enough for large folders.*/
  }

  getRevisions(orderId: string, subscriptionId: string, resourceType: string,
    skyKickId: string = null, parentSkyKickId: string = null): Observable<models.RevisionEntity[]> {
    let url = `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/revisions`;
    if (skyKickId != null && parentSkyKickId != null) {
      url = `${url}?skyKickId=${skyKickId}&parentSkyKickId=${parentSkyKickId}`;
    }
    return this.makeRequest('POST',
      url,
      null,
      null,
      true);
  }

  getBackupEntities(orderId: string, subscriptionId: string, resourceType: string,
    skyKickIds: string[], reload = false): Observable<models.BackupEntity[]> {
    const body = {
      skyKickIds
    };

    return this.makeRequest('POST',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/backup-entities`,
      body,
      null,
      reload);
  }

  findSubscriptionJobs(orderId: string, subscriptionId: string, resourceType: string, days: number, reload: boolean): Observable<models.BackupJob[]> {
    let url = `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/jobs`;
    if (days != null) {
      url += `?days=${days}`;
    }

    return this.makeRequest('GET',
      url,
      null,
      null,
      reload);
  }

  fetchSubscriptionHealth(orderId: string, subscriptionId: string, reload = false): Observable<models.SubscriptionHealth> {
    const body = {
      orderId: orderId,
      subscriptionId: subscriptionId
    };

    return this.makeRequest('GET',
      `${environment.api_url()}/subscriptions/${subscriptionId}/health`,
      body,
      null,
      reload);
  }

  fetchPartner(partnerId: string, reload = false): Observable<models.Partner> {
    return this.makeRequest('GET',
      `${environment.api_url()}/partners/${partnerId}`,
      null,
      null,
      reload);
  }

  findOrdersByPartnerId(partnerId: string, filters: models.PartnerOrdersFilter, reload = false): Observable<models.PartnerOrdersPage> {
    const body = {
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
      state: filters.orderState,
    };

    return this.makeRequest('POST',
      `${environment.api_url()}/partners/${partnerId}/orders`,
      body,
      null,
      reload);
  }

  replacePartner(orderId: string, newPartnerId: string): Observable<models.BackupOrder> {
    const body = {
      newPartnerId: newPartnerId
    };

    return this.makeRequest('PUT',
      `${environment.api_url()}/orders/${orderId}/partner`,
      body,
      null,
      true);
  }

  fetchEventsForRequest(requestId: string, reload: boolean): Observable<models.ResourceEvent[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/resource-events/${requestId}`,
      null,
      null,
      reload);
  }

  getRestoreJobsForSubscription(subscriptionId: string, reload: boolean): Observable<models.BackupJob[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/restores?subscriptionId=${subscriptionId}`,
      null,
      null,
      reload);
  }

  getRestoreJobsForOrder(orderId: string, reload: boolean): Observable<models.BackupJob[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/restores?orderId=${orderId}`,
      null,
      null,
      reload);
  }

  getRestoreJobsForOrderDateRange(orderId: string, reload: boolean, startDate: string, endDate: string): Observable<models.BackupJob[]>{
    return this.makeRequest('GET',
      `${environment.api_url()}/restores/dateRange?orderId=${orderId}&startDate=${startDate}&endDate=${endDate}`,
      null,
      null,
      reload);
  }

  getRestoreJobsForPartner(partnerId: string, reload: boolean): Observable<models.BackupJob[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/restores?partnerId=${partnerId}`,
      null,
      null,
      reload);
  }

  getRestoreJobs(reload: boolean): Observable<models.BackupJob[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/restores`,
      null,
      null,
      reload);
  }

  getIsAzureIpAddress(ipAddress: string, reload = false): Observable<models.AzureIpAddressCheck> {
    return this.makeRequest('GET',
      `${environment.api_url()}/utility/is-azure-ip-address?ipAddress=${encodeURI(ipAddress)}`,
      null,
      null,
      reload);
  }

  bulkOperationsPrecheck(orderId: string, request: models.BulkOperationRequest, reload = false):
    Observable<models.BulkOperationPrecheckResult[]> {
    return this.makeRequest('POST',
      `${environment.api_url()}/bulkoperations/${orderId}/precheck`,
      request,
      null,
      reload);
  }

  reindexSubscription(orderId: string,
    subscriptionId: string,
    resourceType: string,
    request: models.ReindexSubscriptionRequest,
    reload = false):
    Observable<string> {

    return this.makeRequest('POST',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/reindex`,
      request,
      null,
      reload,
      null,
      false);
  }

  bulkOperationsAct(orderId: string, request: models.BulkOperationRequest, reload = false):
    Observable<models.BulkOperationPrecheckResult[]> {
    return this.makeRequest('POST',
      `${environment.api_url()}/bulkoperations/${orderId}/act`,
      request,
      null,
      reload,
      null,
      false);
  }

  getSwatTickets(partnerName: string, customerName: string, reload = false):
    Observable<models.SwatTickets> {



    return this.makeRequest('GET',
      `${environment.api_url()}/tickets/swatTickets?partnerName=${encodeURIComponent(partnerName ?? '')}&customerName=${encodeURIComponent(customerName)}`,
      null,
      null,
      reload,
      null,
      false);
  }

  revertDeactivationRequest(orderId: string, reload = false):
    Observable<models.BackupOrder> {

    return this.makeRequest('POST',
      `${environment.api_url()}/orders/${orderId}/revertDeactivationRequest`,
      null,
      null,
      reload);
  }

  getElasticSearchLastIndexDate(orderId: string, resourceKey: models.ResourceKey, reload = false): Observable<Moment> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/elasticSearchLastIndexDate?resourceType=${resourceKey.resourceType}`,
      null,
      null,
      reload);
  }

  searchInfo(orderId: string, resourceKey: models.ResourceKey, request: ElasticSearchRequest, reload = false): Observable<BackupEntity[]> {
    return this.makeRequest('POST',
      `${environment.api_url()}/orders/${orderId}/subscriptions/${resourceKey.subscriptionId}/${resourceKey.resourceType}/searchInfo`,
      request,
      null,
      reload,
      null,
      false);
  }

  getEntityDetails(orderId: string, subscriptionId: string, resourceType: string,
    skyKickId: string = null, parentSkyKickId: string = null): Observable<models.BackupEntity> {
    let url = `${environment.api_url()}/orders/${orderId}/subscriptions/${subscriptionId}/${resourceType}/entitydetails`;
    if (skyKickId != null && parentSkyKickId != null) {
      url = `${url}?skyKickId=${skyKickId}&parentSkyKickId=${parentSkyKickId}`;
    }
    return this.makeRequest('POST',
      url,
      null,
      null,
      true);
  }

  transitionPartnerToV2(partnerId: string): Observable<models.GenericResponse> {
    return this.makeRequest('POST',
      `${environment.api_url()}/partners/${partnerId}/transitionPartnerToV2`,
      null,
      null,
      true);
  }

  getResourceCounts(backupServiceId: string, reload = false): Observable<OrderResourceCount[]> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${backupServiceId}/resourceCount`,
      null,
      null,
      reload);
  }

  bulkReindexSubscription(backupServiceId: string, request: BulkReindexOrderRequest, reload = false): Observable<BulkReindexOrderResponse> {
    return this.makeRequest('POST',
      `${environment.api_url()}/orders/${backupServiceId}/bulkReindex`,
      request,
      null,
      reload,
      null,
      false);
  }

  // I'm sure there's a way to tighten this up, but it's meant to be extremely generic
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private makeRequest<T>(method: string,
    url: string,
    body: any,
    responseType: any,
    reload: boolean,
    timeout?: string,
    handleErrors = true,
    handleErrorExplicitly = false
  ): Observable<T> {

    return this.skApiService.makeRequest(method, url, body, responseType, reload, timeout, handleErrors, handleErrorExplicitly);
  }

  fetchSnapshotQueueName(backupServiceId: string, resourceKey: models.ResourceKey): Observable<SnaphostFrequencyInfo> {
    return this.makeRequest('GET',
      `${environment.api_url()}/orders/${backupServiceId}/snapshot-frequency-info/${resourceKey.resourceType}`,
      null,
      null,
      true
    );
  }

  reindexRestore(restoreId: string, data: models.RestoreReindexRequest): Observable<any> {
    return this.makeRequest('POST',
      `${environment.api_url()}/jobs/${restoreId}/restore-reindex`,
      data,
      null,
      true);
  }
}
